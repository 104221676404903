// Данный файл - лишь собрание подключений готовых компонентов.
// Рекомендуется создавать отдельный файл в папке components и подключать все там

// Определение операционной системы на мобильных
import { mobileCheck } from "./functions/mobile-check";
console.log(mobileCheck())

// Определение ширины экрана
// import { isMobile, isTablet, isDesktop } from './functions/check-viewport';
// if (isDesktop()) {
//   console.log('...')
// }

// Троттлинг функции (для ресайза, ввода в инпут, скролла и т.д.)
// import { throttle } from './functions/throttle';
// let yourFunc = () => { console.log('throttle') };
// let func = throttle(yourFunc);
// window.addEventListener('resize', func);

// Фикс фулскрин-блоков
// import './functions/fix-fullheight';

// Реализация бургер-меню
// import { burger } from './functions/burger';

// Реализация остановки скролла (не забудьте вызвать функцию)
// import { disableScroll } from './functions/disable-scroll';

// Реализация включения скролла (не забудьте вызвать функцию)
// import { enableScroll } from './functions/enable-scroll';

// Реализация модального окна
// import GraphModal from 'graph-modal';
// const modal = new GraphModal();

// Реализация табов
// import GraphTabs from 'graph-tabs';
// const tabs = new GraphTabs('tab');

// Получение высоты шапки сайта (не забудьте вызвать функцию)
// import { getHeaderHeight } from './functions/header-height';

// Подключение плагина кастом-скролла
// import 'simplebar';

// Подключение плагина для позиционирования тултипов
// import { createPopper, right} from '@popperjs/core';
// createPopper(el, tooltip, {
//   placement: 'right'
// });

//Подключение свайпера
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay]);

const swiper = new Swiper('.review__slider', {
  slidesPerView: 1,
  centeredSlides: true,
  loop: true,
  spaceBetween: 20,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  breakpoints: {
    767: {
      slidesPerView: 1.7,
    },
    1024: {
      spaceBetween: 40,
      slidesPerView: 2.4,
    },
    1600: {
      slidesPerView: 3.4,
    },
    1920: {
      slidesPerView: 4.4,
    },
  }
});


let popupBg = document.querySelector('.popup__bg');
let popup = document.querySelector('.popup');
let openPopupButtons = document.querySelectorAll('.open-popup');
let closePopupButton = document.querySelector('.close-popup');


openPopupButtons.forEach((button) => {
  button.addEventListener('click', (e) => {
    e.preventDefault();
    popupBg.classList.add('active');
    popup.classList.add('active');
  })
});

closePopupButton.addEventListener('click',() => {
  popupBg.classList.remove('active');
  popup.classList.remove('active');
});

document.addEventListener('click', (e) => {
  if(e.target === popupBg) {
    popupBg.classList.remove('active');
    popup.classList.remove('active');
  }
});

// Подключение анимаций по скроллу
// import AOS from 'aos';
// AOS.init();

// Подключение параллакса блоков при скролле
// import Rellax from 'rellax';
// const rellax = new Rellax('.rellax');

// Подключение плавной прокрутки к якорям
// import SmoothScroll from 'smooth-scroll';
// const scroll = new SmoothScroll('a[href*="#"]');

// Подключение событий свайпа на мобильных
// import 'swiped-events';
// document.addEventListener('swiped', function(e) {
//   console.log(e.target);
//   console.log(e.detail);
//   console.log(e.detail.dir);
// });

// import { validateForms } from './functions/validate-forms';
// const rules1 = [...];

// const afterForm = () => {
//   console.log('Произошла отправка, тут можно писать любые действия');
// };

// validateForms('.form-1', rules1, afterForm);




const burger = document.querySelector('.burger');
const menu = document.querySelector('.header__menu');
const headerBtn = document.querySelector('.header__btn');


burger.addEventListener('click', () => {
  burger.classList.toggle('active');
  menu.classList.toggle('active');
  headerBtn.classList.toggle('active');
})


const aboutBtn = document.querySelector('.deal__btn')
const about = document.querySelector('.deal')
const aboutText = document.querySelector('.deal__toggle--full')
aboutBtn ? aboutBtn.addEventListener('click', () => {
  about.classList.toggle('active');
  aboutText.classList.toggle('active');
}) : null


const height = window.innerHeight;
const siteContainer = document.querySelector('.site-container');

document.addEventListener('resize', () => {
  siteContainer.style.minHeight = height + 'px';
})


const btnTip = document.querySelector('.column__tip');
const columnTip = document.querySelector('.column--params .tip');
const flatTip = document.querySelector('.column--offer .tip')
const columnTipClose = document.querySelector('.column--params .tip__close');

btnTip ? btnTip.addEventListener('click', () => {
  btnTip.classList.remove('active');
  columnTip.classList.add('active');
}) : null
columnTipClose ? columnTipClose.addEventListener('click', () => {
  btnTip.classList.add('active');
  columnTip.classList.remove('active');
}) : null






function formatNumber(input) {
  input.addEventListener('input', function (evt) {
    input.value = input.value
        .replace(/[^0-9.]/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  });
}


function generateHTML(item) {
  // Создаем элементы
  let li = document.createElement('li');
  li.className = 'list__item';

  let h3 = document.createElement('h3');
  h3.className = 'item__title';

  let spanRoom = document.createElement('span');
  spanRoom.className = 'item__room';
  spanRoom.textContent = item.rooms_num + 'К';

  let spanArea = document.createElement('span');
  spanArea.className = 'item__area';
  spanArea.innerHTML = item.area.toFixed(0) + '<sup>М2</sup>';

  let divAddr = document.createElement('div');
  divAddr.className = 'item__addr';
  let addrArray = item.address.includes('городской округ') ? item.address.split(',').filter(Boolean) : null
  !!addrArray ? addrArray.splice(1, 1) : null;
  divAddr.textContent = !!addrArray ? addrArray.toString().trim() : item.address;

  let divPrice = document.createElement('div');
  divPrice.className = 'item__price';
  let priceItem = item.currency == '$' ? item.price * 100 : item.price
  divPrice.textContent = priceItem.toLocaleString('ru');

  let button = document.createElement('button');
  button.className = 'item__btn';
  button.textContent = 'Выбрать';

  let img = document.createElement('img');
  img.src = `/wp-content/themes/vsedoma/front/app/img/itemflat${Math.floor(Math.random() * 3) + 1}.webp`;
  img.className = 'item__img';

  // Собираем элементы вместе
  h3.appendChild(spanRoom);
  h3.appendChild(spanArea);

  li.appendChild(h3);
  li.appendChild(divAddr);
  li.appendChild(divPrice);
  li.appendChild(button);
  li.appendChild(img);

  // Возвращаем сгенерированный HTML-код
  return li.outerHTML;
}



if(window.location.pathname == '/konstruktor/') {

  /*calc*/


  function getStageOne() {

  }

  function getStageTwo() {

  }

  function getStageThree() {

  }


// ОПРЕДЕЛЕНИЕ ЦЕНЫ СВОЕЙ КВАРТИРЫ
  const wrapperConstr = document.querySelector('.constructor__form'); //Рапер для могу
  const btnPlate = wrapperConstr.querySelector('.plate__btn'); //Кнопка добавить
  const infoPlate = wrapperConstr.querySelector('.plate__info'); //Инфо-плашка
  const formPlate = wrapperConstr.querySelector('.plate__form'); //Форма моя собственность
  const stash = document.querySelector('.input--money'); //Ввод своих денег
  const monthly = document.querySelector('.input--month'); //Ввод месячных денег
  const motherCapital = document.querySelector('.input--capital'); //инпут на материнский капитал
  const fullPrice = document.querySelector('.own__stash'); //общие деньги, которые есть
  const ownText = document.querySelector('.own__info'); //подсказка внизу
  const addressInput = document.querySelector('.input--address'); //Ввод адреса
  const constForm = wrapperConstr.querySelector('.own__plate'); //Форма могу


  const formArea = document.querySelector('.form__area');
  const inputArea = document.querySelector('.input--area');

  inputArea ? inputArea.onfocus = () => {
    formArea.classList.add('active');
  } : null
  inputArea.onblur = () => {
    formArea.classList.remove('active');
  }


//formuls
  let groupName;
  let formulValue;


  let mc = 0;
  let firstGeoObject;
  let region;
  let address;
  let cost = 0;
  let fullMoney = 0;
  let addressCheck = false;
  let areaCheck = false;
  let roomsCheck = false;
  let locality;

  let addressCheckOwn = false;
  let findAddressCheck = false;

  let flatOwnRegion = 'Московская область';
  let arRegion = [['Республика Марий Эл', 12], ['Республика Татарстан', 16], ['Краснодарский край', 23], ['Калининградская область', 39], ['Кировская область', 43], ['Ленинградская область', 47], ['Московская область', 50], ['Нижегородская область', 52], ['Новосибирская область', 54], ['Пермский край', 59], ['Ростовская область', 59], ['Рязанская область', 62], ['Саратовская область', 64], ['Свердловская область', 66], ['Москва', 77], ['Санкт-Петербург ', 78]]


  /*Данные по квартире*/
  let finishRoom = document.querySelector('.finish__room');
  let finishArea = document.querySelector('.finish__area');
  let finishAddr = document.querySelector('.finish__address');
  let finishPrice = document.querySelector('.finish__price');


  const pageHero = document.querySelector('.constructor__tip--mobile')
  /*Разрядность*/
// inputCheckCount(stash);
// inputCheckCount(monthly);


  /*Функция для разбиения на разряды*/
// stash.addEventListener('input', e => {
//   this.value = this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
// })

  formatNumber(stash)
  formatNumber(monthly)
  inputCheckCount(inputArea)

//Ввод только цифр
  function inputCheckCount(input) {
    input.addEventListener('input',
        function (e) {
          this.value = this.value.replace(/[^\d.]/g, '');
        }
    )
  }


  /*geo подстановка адреса в форму продажи своей квартиры*/
  ymaps.ready(init);

  function init() {
    let suggestView = new ymaps.SuggestView(addressInput, {
      provider: {
        suggest: (function (request, options) {
          return ymaps.suggest("Россия" + ", " + request);
        })
      }
      // boundedBy: [[41.1859504, 19.6389224], [82.169819, 179.793251]], // Границы России
      // strictBounds: true // Строгие границы, только в пределах boundedBy
    });
    suggestView.events.add("select", function (e) {
      address = e.get("item").value;

      // Геокодируем выбранный адрес
      ymaps.geocode(address).then(function (res) {
        firstGeoObject = res.geoObjects.get(0);
        // region = firstGeoObject.getAdministrativeAreas().join(", ");
        region = address
        flatOwnRegion = region.split(',')[0];
        addressCheckOwn = true;
        checkInput(btnPlate, addressCheckOwn, inputArea, 'Добавить', addressInput);
      });
    });
  }

  /*кнопка добавить двойное срабатываение */
  btnPlate ? btnPlate.addEventListener('click', () => {
    if (btnPlate.classList.contains('active')) {
      getPriceFlat(address, inputArea.value, flatOwnRegion)
      motherCapital.checked ? mc = 524000 : mc = 0;
      formPlate.classList.remove('active');
      btnPlate.classList.remove('active');
      btnPlate.innerHTML = 'Изменить';
      getFlatLate();
      getRemoveWarn();
    } else if (btnPlate.classList.contains('disabled')) {
      formPlate.classList.remove('active');
      btnPlate.classList.remove('disabled');
      btnPlate.innerHTML = 'Изменить';
    } else {
      btnPlate.classList.remove('finish');
      btnPlate.classList.add('disabled');
      infoPlate.classList.add('hide');
      formPlate.classList.add('active');
      wrapperConstr.classList.add('plate');
      btnPlate.innerHTML = 'Отмена';
    }
  }) : null


  function getRemoveWarn() {
    wrapperConstr.classList.remove('warn');
    fullPrice.classList.remove('warn')
  }

  function getActiveWarn() {
    wrapperConstr.classList.add('warn');
    fullPrice.classList.add('warn');
    controlMobile.classList.remove('flat')
    paramsColumn.classList.remove('hide')
    flatColumn.classList.remove('flats-active')
    fullPrice.innerHTML = 'Введите данные в поля для определения ваших возможностей';
  }


  /*Проверка данных*/
// landArea.oninput = function() {
//   if(landArea.value != 0) {
//     btnPlate.disabled = false;
//   }
// }
// Функция посчета всех денег
  function calcFullPrice(costFlat, stashOwm, mc, month) {
    if (costFlat && !!stashOwm.value.replace(/ /g, '') && !!month.value.replace(/ /g, '')) {
      fullMoney = Math.round((Number(month.value.replace(/ /g, '')) / 0.012) * 0.8 + Number(stashOwm.value.replace(/ /g, '')) + mc + cost)
    } else if (costFlat && !!stashOwm.value.replace(/ /g, '')) {
      fullMoney = Math.round((Number(stashOwm.value.replace(/ /g, '')) / 0.1) + mc + cost * 1.3)
    } else if (costFlat && !!month.value.replace(/ /g, '')) {
      fullMoney = Math.round((Number(month.value.replace(/ /g, '')) / 0.012) * 0.8 + mc + cost)
    } else if (!!month.value.replace(/ /g, '') && !!stashOwm.value.replace(/ /g, '')) {
      fullMoney = Math.round(Number(month.value.replace(/ /g, '')) / 0.012 + Number(stashOwm.value.replace(/ /g, '')) + mc)
    } else if (costFlat) {
      fullMoney = Math.round(mc + cost * 1.3)
    } else if (!!stashOwm.value.replace(/ /g, '')) {
      fullMoney = Math.round(Number(stashOwm.value.replace(/ /g, '')) * 10 + mc)
    } else if (!!month.value.replace(/ /g, '')) {
      fullMoney = Math.round(Number(month.value.replace(/ /g, '')) / 0.012 + mc)
    }
    // fullMoney = +cost + Number(stashOwm.value.replace(/ /g,''))  + mc + Number(month.value.replace(/ /g,''))*180;
    fullPrice.innerHTML = '<span>Вы можете купить квартиру стоимостью</span><span>~ ' + Math.round(fullMoney).toLocaleString('ru') + '</span>';
    fullPrice.classList.add('active');
    document.querySelector('.deal__value').innerHTML = fullMoney.toLocaleString('ru');
  }

  /*изменение инпутов*/
  stash.oninput = function () {
    calcFullPrice(cost, stash, mc, monthly);
    ownText.classList.add('hide');
    getRemoveWarn();
    checkWarn()
    document.querySelector('.pass__wrapper .row--stash .money__value').innerHTML = stash.value.replace(/ /g, '') ? Number(stash.value.replace(/ /g, '')).toLocaleString('ru') : 'Не задано';
    flatList ? getListFlat(flatList) : null;
  }

  stash.onblur = function () {
    calcFullPrice(cost, stash, mc, monthly);
    ownText.classList.add('hide');
    getRemoveWarn();
    checkWarn()
    document.querySelector('.pass__wrapper .row--stash .money__value').innerHTML = stash.value.replace(/ /g, '') ? Number(stash.value.replace(/ /g, '')).toLocaleString('ru') : 'Не задано';

    flatList ? getListFlat(flatList) : null;
  }


  monthly.onblur = function () {
    calcFullPrice(cost, stash, mc, monthly);
    checkWarn()
    flatList ? getListFlat(flatList) : null;
  }
  monthly.oninput = function () {
    calcFullPrice(cost, stash, mc, monthly);
    checkWarn()
    flatList ? getListFlat(flatList) : null;
  }

  motherCapital.oninput = function () {

    if (motherCapital.checked) {
      mc = 524000
      document.querySelector('.own__capital').classList.add('active')
      ownText.classList.add('hide')
    } else {
      mc = 0
      document.querySelector('.own__capital').classList.remove('active')
    }
    checkWarn()
    calcFullPrice(cost, stash, mc, monthly);
    flatList ? getListFlat(flatList) : null;
  }

  document.querySelector('.form__room').addEventListener('change', function (e) {
    checkInput(btnPlate, addressCheckOwn, inputArea, 'Добавить', addressInput);
  })


  /*Проверка заполненности*/
  inputArea.oninput = () => {
    checkInput(btnPlate, addressCheckOwn, inputArea, 'Добавить', addressInput);
  }

  function checkInput(btn, addr, area, btnText, addrInput) {
    if (addr && area.value !== '') {
      btn.innerHTML = btnText;
      btn.classList.add('active');
      btn.classList.remove('disabled');
      area.classList.remove('warn');
      addrInput.classList.remove('warn');
    } else {
      if (area.value == '') {
        area.classList.add('warn')
      }
      if (addr == false) {
        addrInput.placeholder = 'Введите данные';
        addrInput.classList.add('warn')
      }
    }
  }

  addressInput.onblur = function () {
    checkInput(btnPlate, addressCheckOwn, inputArea, 'Добавить', addressInput);
  }


  function blockInput(boPoint) {
    stash.disabled = boPoint
    monthly.disabled = boPoint
    motherCapital.disabled = boPoint
    findBtn.disabled = boPoint
    btnPlate.disabled = boPoint
  }


  /*Получаем стоимость квартиры*/
  function getPriceFlat(address, area, numRegion) {
    infoPlate.classList.add('finish');
    finishRoom.innerHTML = document.querySelector('.form__room').value;
    finishArea.innerHTML = inputArea.value + 'м2';
    finishAddr.innerHTML = region;
    constForm.classList.remove('warn')
    infoPlate.classList.add('remove');
    finishPrice.innerHTML = ''
    let reqNumRegion;
    arRegion.forEach(function (region) {
      if (region[0].trim() == numRegion.trim()) {
        reqNumRegion = region[1];
      }
    });
    animationOwn.play();
    constForm.querySelector('svg').classList.add('active');
    ;
    if (reqNumRegion) {
      fetch('https://d5duiqnab9gjniqmttue.apigw.yandexcloud.net/price_prediction?token=BKNTgMETEfGZjRKprWCau3W4bX2Nn3QHj5ExQE3k&region_code=' + reqNumRegion + '&property_type=flat&address=' + address + '&living_area=' + area)
          .then(function (response) {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error('Error: ' + response.status);
            }
          })
          .then(function (data) {
            animationOwn.stop()
            constForm.querySelector('svg').classList.remove('active');
            if (data.body.cost != undefined) {
              cost = data.body.cost;
              finishPrice.innerHTML = '~ ' + cost.toLocaleString('ru');
              smallestPrice = cost;
              calcFullPrice(cost, stash, mc, monthly);
              ownText.classList.add('hide');

              document.querySelector('.pass__wrapper .row--flat .money__value').innerHTML = cost && cost != 0 ? '~' + cost.toLocaleString('ru') : 'Не задано';
            } else {
              constForm.classList.add('warn')
              infoPlate.classList.add('warn');
              infoPlate.classList.remove('hide');
              infoPlate.classList.remove('finish');
              infoPlate.querySelector('.info__text').innerHTML = 'Ошибка выбора региона квартиры';
            }

            checkWarn()
            flatList ? getListFlat(flatList) : null;
            calcFullPrice(cost, stash, mc, monthly);
          })
          .catch(function (error) {
            console.log(error);
            checkWarn()
          });
    } else {
      constForm.querySelector('svg').classList.remove('active');
      animationOwn.stop()
      constForm.classList.add('warn')
      infoPlate.classList.add('warn');
      infoPlate.classList.remove('hide');
      infoPlate.classList.remove('finish');
      infoPlate.querySelector('.info__text').innerHTML = 'Ошибка выбора региона квартиры';
    }

  }


  /*Поиск квартиры*/

  let findPlate = document.querySelector('.new__plate')
  let findBtn = document.querySelector('.new__plate .plate__btn')
  let findText = document.querySelector('.new__plate .plate__info')
  const findFormPlate = document.querySelector('.new__plate .plate__form');
  const findAddressInput = document.querySelector('.new__plate .input--address');
  const findAreaInput = document.querySelector('.new__plate .input--area');
  const findSelectRoom = document.querySelector('.new__plate .form__room')

  let findRegion;
  let coordinates;
  let metroName;

  let flatList;
  /* нижняя грань цены */
  let smallestPrice = 0;
  let checkDataUser = 0;
  let textRegion = 'Московская область';

  const paramsColumn = document.querySelector('.column--params');
  const flatColumn = document.querySelector('.column--offer');

  let columnFloatLoading = flatColumn.querySelector('.column__loading')

  /*wrapper для квартир*/
  const flatWrapper = document.querySelector('.column--offer .offer__wrapper');
  const flatWrapperList = document.querySelector('.column--offer .offer__wrapper .offer__list');
  const flatСount = document.querySelector('.column--offer .count__value');
  let controlMobile = document.querySelector('.constructor__control');
  let controlMobileBtn = controlMobile.querySelector('.control__btn');
  let wrapperError = flatWrapper.querySelector('.offer__error')

  /*Данные по квартире*/
  let findFinishRoom = document.querySelector('.new__plate .finish__room');
  let findFinishArea = document.querySelector('.new__plate .finish__area');
  let findFinishAddr = document.querySelector('.new__plate .finish__address');
  let findFinishPrice = document.querySelector('.new__plate .finish__price');
  let avPriceFlat = 0;
  let smPriceFlat;
  /*filter offer*/
  const filterOffer = document.querySelector('.column--offer .plate__form');
  const filterOfferAddr = filterOffer.querySelector('.input--address');
  const filterOfferArea = filterOffer.querySelector('.input--area');
  const filterOfferRoom = filterOffer.querySelector('.form__room');

  /*controls on offer*/
  const controlFilterOfferBtn = document.querySelector('.offer__controls .controls__option')
  const controlFilterOffer = document.querySelector('.offer__controls')
  const FilterOffer = document.querySelector('.offer__wrapper .new__plate')
  const FilterOfferBtn = document.querySelector('.offer__wrapper .new__plate .row__btn')

  /*button bottom get flat late*/
  let btnOfferLate = document.querySelector('.offer__late');

  /*warn*/
  const warnPriceWrap = document.querySelector('.constructor__need')


  inputCheckCount(findAreaInput)

  /*кнопка добавить двойное срабатываение */
  findBtn ? findBtn.addEventListener('click', () => {
    if (findBtn.classList.contains('active')) {
      getFindFlat(findAreaInput, findSelectRoom, coordinates, textRegion, locality, metroName);
      findBtn.classList.remove('active');
      findBtn.innerHTML = 'Изменить';
      findPlate.classList.add('finish');
      findFormPlate.classList.remove('active');
      findPlate.classList.remove('active');
      filterOfferAddr.value = findAddressInput.value
      filterOfferArea.value = findAreaInput.value
      filterOfferRoom.value = findSelectRoom.value
      flatsPass.classList.remove('active');
      controlFilterOffer.classList.remove('hide')
      pageHero.classList.add('hide')
      btnCreditLate.classList.add('hide')
    } else if (findBtn.classList.contains('disabled')) {
      findBtn.classList.remove('disabled');
      findBtn.innerHTML = 'Изменить';
      findFormPlate.classList.remove('active');
      if (!flatList) {
        findText.classList.remove('hide')
      } else {
        findText.classList.add('finish')
        findPlate.classList.add('finish')
      }
      findFormPlate.classList.remove('active');
      findPlate.classList.remove('active');
    } else {
      findBtn.classList.remove('finish');
      findBtn.classList.add('disabled');
      findBtn.innerHTML = 'Отмена';
      findText.classList.add('hide');
      findFormPlate.classList.add('active');
      findPlate.classList.add('active');
      findPlate.classList.remove('finish');
      findText.classList.remove('finish');
    }
  }) : null

  geoNewFlat(findAddressInput, findBtn, findAddressCheck, findAreaInput);
  geoNewFlat(filterOfferAddr, FilterOfferBtn, addressCheck, filterOfferArea);

  function geoNewFlat(input, btn, addressInput, areaInput) {
    /*geo подстановка адреса для поиска квартиры */
    ymaps.ready(initFind);

    function initFind() {
      // Указываем ограничение геокодинга только для России
      const geocodeOptions = {
        boundedBy: [[55.55, 19.61], [41.19, 190.37]] // Координаты ограничивающего прямоугольника для России
      };

      let suggestView = new ymaps.SuggestView(input, {
        provider: {
          suggest: (function (request, options) {
            return ymaps.suggest("Россия" + ", " + request);
          })
        }
      });
      suggestView.events.add("select", function (e) {
        let findAddress = e.get("item").value;

        // Геокодируем выбранный адрес с ограничением по России
        ymaps.geocode(findAddress, geocodeOptions).then(function (res) {
          firstGeoObject = res.geoObjects.get(0);
          locality = firstGeoObject.getLocalities().join(", ");
          // findRegion = firstGeoObject.getAdministrativeAreas().join(", ");
          findRegion = findAddress
          let coordsOfFind = firstGeoObject.geometry.getCoordinates();
          findAddress ? checkDataUser = 1 : null;
          textRegion = findRegion;
          findAddressCheck = true;
          let distOfFind = 10
          if (findAddress.includes('улица') || findAddress.includes('проспект')) {
            distOfFind = 3
          } else {
            distOfFind = 10
          }
          if(findAddress != 'Москва ') {
            ymaps.geocode(coordsOfFind, { kind: 'metro' }).then(function (metroResult) {
              let metroObject = metroResult.geoObjects.get(0);
              metroName = metroObject.properties.get('name');
            })
            if(findAddress.includes('метро')) {
            } else {
              coordinates = increaseArea(coordsOfFind[0], coordsOfFind[1], distOfFind)
            }

          } else {
            metroName = null
          }

          checkInput(btn, findAddressCheck, areaInput, 'Искать', input);

        });
      });
    }
  }


  findAreaInput.oninput = () => {
    checkInput(findBtn, findAddressCheck, findAreaInput, 'Искать', findAddressInput);
  }
// findAddressInput.onblur = function () {
//   if(addressInput.value == '' || addressCheck == false) {
//     findAddressInput.placeholder='Введите данные';
//     findAddressInput.classList.add('warn');
//   } else {
//     findAddressInput.classList.remove('warn');
//   }
// }
  findSelectRoom.addEventListener('change', function (e) {
    checkInput(findBtn, findAddressCheck, findAreaInput, 'Искать', findAddressInput);
  })
  inputCheckCount(findAreaInput);

  /*Получаем варианты квартир*/
  function getFindFlat(area, roomNumbers, coords, numRegion, city) {
    city = locality
    numRegion = numRegion.split(',')[0]
    let regionName = 0;
    let reqString = '';
    arRegion.forEach(function (region) {
      if (region[0].trim() == numRegion.trim()) {
        reqString += '&region_code=' + region[1];
        regionName = region[1];
        if(region[1] == '77' || region[1] == '78') {
          city = 0;
          if(metroName) {
            reqString += '&subway_station=' + metroName.split(' ')[1];
          } else {
            if(coords) {
              coords.toString()
            }
            const formattedCoordinates = coords ? coords.map(coord => `(${coord.lat},${coord.lng})`).join(',') : null;
            formattedCoordinates ? reqString += '&poly_vertex=[' + formattedCoordinates + ']' : null;
          }
        }
      } else {

      }
    });



    area.value ? reqString += '&min_area=' + area.value : null
    roomNumbers.value ? reqString += '&room_numbers=' + roomNumbers.value[0] : null
    city ? reqString += '&city=' + city.trim() : null


    findText.classList.add('finish');
    findFinishRoom.innerHTML = findSelectRoom.value;
    findFinishArea.innerHTML = findAreaInput.value + 'м2';
    findFinishAddr.innerHTML = findRegion;
    getFlatLate();
    const widthDesc = window.innerWidth;
    if(widthDesc > 1023 ) {
      btnOfferLate.classList.add('active')
    }
    flatWrapper.classList.remove('active')
    animationFind.play();
    animationFindColumn.play();
    columnFloatLoading.classList.add('active');
    findPlate.querySelector('svg').classList.add('active');
    flatTip.classList.remove('active')
    findFinishPrice.innerHTML = ''
    if (regionName) {
      fetch('https://d5duiqnab9gjniqmttue.apigw.yandexcloud.net/property_ads_catalog?api_key=BKNTgMETEfGZjRKprWCau3W4bX2Nn3QHj5ExQE3k' + reqString)
          .then(function (response) {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error('Error: ' + response.status);
            }
          })
          .then(function (data) {
            animationFind.stop()
            findPlate.querySelector('svg').classList.remove('active');
            animationFindColumn.stop();
            columnFloatLoading.classList.remove('active');

            avPriceFlat = Math.round(Object.values(data).reduce((acc, number) => acc + number.price, 0) / Object.values(data).length)
            if (Object.values(data).length !== 0) {
              flatList = Object.values(data).sort((a, b) => b.price - a.price);
              Object.values(data).forEach((item, index) => {
                let priceItem = item.currency == '$' ? item.price * 100 : item.price
                if(index == 0) {
                  smPriceFlat = Number(priceItem)
                } else {
                  Number(smPriceFlat) > Number(priceItem) ? smPriceFlat = priceItem : null
                }
              })

              getListFlat(flatList);
              checkWarn()
            } else {
              avPriceFlat = 0
              smPriceFlat = 0
              getError();
            }

            // calcFullPrice(cost,stash, mc);
          })
          .catch(function (error) {
            console.log(error);
            animationFind.stop()
            findPlate.querySelector('svg').classList.remove('active');
            columnFloatLoading.classList.remove('active');
            animationFindColumn.stop();
            getError();

          });
    } else {
      animationFind.stop()
      findPlate.querySelector('svg').classList.remove('active');
      columnFloatLoading.classList.remove('active');
      animationFindColumn.stop();
      getError();
    }

  }

  function getError() {
    flatWrapper.classList.add('active');
    flatTip.classList.remove('active');
    flatWrapperList.innerHTML = ' ';
    flatСount.innerHTML = '0';
    flatWrapperList.classList.add('hide');
    wrapperError.classList.add('active')
    getFlatLate();
  }


  /*flat vivod*/
  function getListFlat(flats) {
    flatTip.classList.remove('active');
    flatWrapper.classList.add('active')
    flatWrapperList.replaceChildren();
    checkWarn();
    if (ownMoney) {
      Object.values(flats).forEach((item) => {
        let priceItem = item.currency == '$' ? item.price * 100 : item.price
        if (priceItem <= ownMoney) {
          let generatedHTML = generateHTML(item);
          flatWrapperList.insertAdjacentHTML('afterbegin', generatedHTML);
        }
      })
    } else {
      Object.values(flats).forEach((item) => {
        let generatedHTML = generateHTML(item);
        flatWrapperList.insertAdjacentHTML('afterbegin', generatedHTML);
      })
    }
    if (flatWrapperList.querySelectorAll('.list__item').length > 0) {
      flatWrapperList.classList.remove('hide')
      wrapperError.classList.remove('active')
      smallestPrice = flatWrapperList.querySelectorAll('.list__item')[0].querySelector('.item__price').innerHTML
      findFinishPrice.innerHTML = (!!fullMoney || !fullMoney.isNaN) && Number(fullMoney) > Number(avPriceFlat) ? fullMoney.toLocaleString('ru') : Math.round(avPriceFlat).toLocaleString('ru');
      flatСount.innerHTML = flatWrapperList.querySelectorAll('.list__item').length;
      if(Number(fullMoney) < Number(smPriceFlat) && !fullMoney.isNaN && fullMoney != 0) {
        warnPriceWrap.classList.add('warn')
      } else {
        warnPriceWrap.classList.remove('warn')
      }
      getPass();

    } else {
      getError();
      flatColumn.classList.add('flats');
      controlMobileBtn.classList.remove('hide');
    }
    window.scrollTo(0, 800)
    // smallestPrice = Object.values(flats)[length -1] ? Object.values(flats)[length -1].price : null; // error
    //
    // findFinishPrice.innerHTML = Number(smallestPrice)
    // fullMoney == 0 ||  fullMoney < smallestPrice ? findFinishPrice.innerHTML = '~' + Number(smallestPrice).toLocaleString('ru') : findFinishPrice.innerHTML = '~' + Number(fullMoney).toLocaleString('ru');


  }


  let ownMoney

  function checkWarn() {
    ownMoney = fullMoney
    // if (Number(ownMoney) < Number(smallestPrice) || ownMoney == null) {
    //   warnPriceWrap.classList.add('warn')
    // } else {
    //   warnPriceWrap.classList.remove('warn')
    // }
    getFlatLate()
    findFinishPrice.innerHTML = (!!fullMoney || !fullMoney.isNaN) && Number(fullMoney) > Number(avPriceFlat) ? fullMoney.toLocaleString('ru') : Math.round(avPriceFlat).toLocaleString('ru');
    if(Number(fullMoney) < Number(smPriceFlat)  && !fullMoney.isNaN && fullMoney != 0) {
      warnPriceWrap.classList.add('warn')
    } else {
      warnPriceWrap.classList.remove('warn')
    }

  }

//.toLocaleString('ru')

  function increaseArea(lat, lng, distance) {
    const earthRadius = 6371; // Радиус Земли в километрах

    // Преобразование градусов в радианы
    const latRad = (lat * Math.PI) / 180;
    const lngRad = (lng * Math.PI) / 180;

    // Расчет новых координат
    const deltaLat = (distance / earthRadius) * (180 / Math.PI);
    const deltaLng = (distance / (earthRadius * Math.cos(latRad))) * (180 / Math.PI);

    const latMin = lat - deltaLat;
    const latMax = lat + deltaLat;
    const lngMin = lng - deltaLng;
    const lngMax = lng + deltaLng;

    // Возвращение координат
    return [
      {lat: latMin, lng: lngMin},
      {lat: latMax, lng: lngMin},
      {lat: latMax, lng: lngMax},
      {lat: latMin, lng: lngMax}
    ];
  }




  document.querySelector('.offer__tap').addEventListener('touchend', () => {
    if (!paramsColumn.classList.contains('hides')) {
      flatColumn.classList.add('flats-active');
      paramsColumn.classList.add('hide');
      controlMobile.classList.add('flat');
      controlMobileBtn.classList.remove('hide');
      getFlatLate();
    } else if (paramsColumn.classList.contains('flats-active')) {
      console.log('123321')
    }
  })

  function getFlatLate() {
    if(flatColumn.classList.contains('flats-active') && flatList) {
      btnOfferLate.classList.remove('hide')
    } else if(fullMoney > 0 && !flatColumn.classList.contains('flats-active') && !flatList) {
      btnOfferLate.classList.remove('hide')
    } else {
      btnOfferLate.classList.add('hide')

    }
  }


  controlFilterOfferBtn.addEventListener('click', () => {
    controlFilterOffer.classList.add('hide')
    FilterOffer.classList.add('active')
    FilterOffer.children[1].classList.add('active')
    getFlatLate();
  })

  FilterOfferBtn.addEventListener('click', () => {
    if (FilterOfferBtn.classList.contains('active')) {
      getFindFlat(filterOfferArea, filterOfferRoom, coordinates, textRegion, locality, metroName);
      FilterOffer.classList.remove('active')
      FilterOffer.children[1].classList.remove('active')
      controlFilterOffer.classList.remove('hide')
      FilterOfferBtn.classList.remove('active')
      FilterOfferBtn.innerHTML = 'Отмена'
      findAddressInput.classList.remove('warn')
      findAddressInput.value = filterOfferAddr.value
      findAreaInput.value = filterOfferArea.value
      findSelectRoom.value = filterOfferRoom.value
    } else {
      FilterOffer.classList.remove('active')
      FilterOffer.children[1].classList.remove('active')
      controlFilterOffer.classList.remove('hide')
    }
  })


  filterOfferArea.oninput = () => {
    checkInput(FilterOfferBtn, findAddressCheck, filterOfferRoom, 'Изменить', filterOfferAddr);
  }
  filterOfferRoom.addEventListener('change', function (e) {
    checkInput(FilterOfferBtn, findAddressCheck, filterOfferRoom, 'Изменить', filterOfferAddr);
  })


  /*passport*/


  let flatsPass = document.querySelector('.pass__wrapper')
  let backBtn = flatsPass.querySelector('.control__back')
  let changeBtn = flatsPass.querySelector('.pass__my .row__btn')
  let infoPassBtn = flatsPass.querySelector('.info__btn')
  let infoPassWrapper = flatsPass.querySelector('.info__wrapper')
  let infoPass = flatsPass.querySelector('.pass__info')

  const chosenFlat = flatsPass.querySelector('.flat--wrapper');
  const passFormSend = document.querySelector('.form__wrapper');
  const passFormWrap = document.querySelector('.pass__form');
  const finishWrap = document.querySelector('.finish__wrapper');
  const passFormButtons = passFormSend.querySelectorAll('.form__tab');
  const passFormInput = passFormSend.querySelector('.form__input');

  let titleFlat;
  let priceFlat;
  let indexFlat = '';

  function getPass() {
    let flatsBtn = document.querySelectorAll('.offer__list .item__btn')
    flatsBtn.forEach((el, index) => {
      el.addEventListener('click', () => {
        flatWrapper.classList.remove('active')
        flatsPass.classList.add('active')
        controlMobile.classList.remove('flat');
        controlMobile.classList.add('pass')
        controlMobileBtn.innerHTML = 'Вернуться к объектам'
        paramsColumn.classList.add('hide')
        flatColumn.classList.add('flats-active')
        btnOfferLate.classList.add('hide')
        indexFlat = el.parentNode.childNodes[1].innerHTML.toString()
        titleFlat = el.parentNode.childNodes[0].innerHTML
        priceFlat = el.previousElementSibling.innerHTML
        blockInput(true)
        flatCheck(titleFlat, priceFlat);
        fullMoney > smallestPrice ? groupName = 'ipoteka1' : groupName = 'ipoteka2';
        getACFFields();
        chosenFlat.classList.remove('hide')
        backBtn.classList.remove('hide')
        const widthDesc = window.innerWidth;
        if(widthDesc > 1023 ) {
          btnOfferLate.classList.remove('active')
        }
      })
    })
  }

  backBtn.addEventListener('click', () => {
    flatWrapper.classList.add('active')
    flatsPass.classList.remove('active')
    controlMobileBtn.innerHTML = 'Изменить'
    getFlatLate()
    blockInput(false)
  })
  controlMobileBtn.addEventListener('click', () => {
    if (controlMobile.classList.contains('pass')) {
      flatWrapper.classList.add('active')
      flatsPass.classList.remove('active')
      controlMobileBtn.innerHTML = 'Изменить'
      controlMobile.classList.remove('pass')
      flatWrapper.classList.add('active')
      !controlMobile.classList.contains('flat') ? controlMobile.classList.add('flat') : null
      getFlatLate()
    } else if (controlMobile.classList.contains('flat')) {
      paramsColumn.classList.remove('hide')
      controlMobile.classList.remove('flat')
      flatColumn.classList.remove('flats-active')
      FilterOffer.children[1].classList.remove('active')
      controlFilterOffer.classList.remove('hide')
      FilterOffer.classList.remove('active')
      getFlatLate()
    }
  })
  changeBtn.addEventListener('click', () => {
    if (controlMobile.classList.contains('pass')) {
      controlMobileBtn.innerHTML = 'Изменить'
      controlMobile.classList.remove('flat', 'pass')
      paramsColumn.classList.remove('hide')
      flatColumn.classList.remove('flats-active')
      flatsPass.classList.remove('active')
      flatWrapper.classList.add('active')
      FilterOffer.children[1].classList.remove('active')
      controlFilterOffer.classList.remove('hide')
      FilterOffer.classList.remove('active')
      getFlatLate()
      blockInput(false)
      if (!flatList) {
        controlFilterOffer.classList.add('hide');
        flatTip.classList.add('active');
        flatWrapper.classList.remove('active');
      } else {
        controlFilterOffer.classList.remove('hide');
        flatTip.classList.remove('active');
        flatWrapper.classList.add('active');
      }
    }
  })

  function flatCheck(title, price) {
    document.querySelector('.pass__flat .item__title').innerHTML = title
    document.querySelector('.pass__flat .item__price').innerHTML = price
  };

  infoPassBtn.addEventListener('click', () => {
    if (!infoPassWrapper.classList.contains('active')) {
      infoPassBtn.querySelector('.btn__text').innerHTML = 'Скрыть';
    } else {
      infoPassBtn.querySelector('.btn__text').innerHTML = 'Раскрыть';
    }
    infoPassWrapper.classList.toggle('active');
    infoPassBtn.classList.toggle('active');
    infoPass.classList.toggle('active');
  })


  passFormButtons.forEach((button) => {
    button.addEventListener('click', (e) => {
      e.preventDefault();
      passFormButtons.forEach((el) => {
        el.classList.remove('active');
      })
      e.target.classList.add('active');
      checkSend(e.target);
    })
  });

  let formSendType = 'email';
  function checkSend(btn) {
    formSendType = btn.dataset.send;
    if (formSendType != 'email') {
      passFormInput.setAttribute("type", "text")
      passFormInput.placeholder = '+7 (999) 999-9999';
      Inputmask({
        mask: '+7 (999) 999-9999',
      }).mask(passFormInput);
      passFormInput.value = ''
    } else {
      passFormInput.setAttribute("type", "email")
      Inputmask.remove(passFormInput);
      passFormInput.placeholder = 'Mail@mail.ru'
      passFormInput.value = ''
    }
  }




//credit
  const btnCredit = document.querySelector('.column__money')
  const btnCreditLate = document.querySelector('.offer__late')

  btnCredit.addEventListener('click', () => {
    groupName = 'credit1';
    getCredit();
    btnCreditLate.classList.add('hide')
    btnCreditLate.classList.remove('active')
  })

  btnCreditLate.addEventListener('click', () => {
    groupName = 'credit2';
    getCredit();
    btnCreditLate.classList.add('hide')
    btnCreditLate.classList.remove('active')
  })

  function getCredit() {
    if (!!cost) {
      flatWrapper.classList.remove('active')
      flatsPass.classList.add('active')
      controlMobile.classList.remove('flat');
      controlMobile.classList.add('pass')
      controlMobileBtn.innerHTML = 'Вернуться к объектам'
      paramsColumn.classList.add('hide')
      flatColumn.classList.add('flats-active')
      flatTip.classList.remove('active');
      chosenFlat.classList.add('hide');
      pageHero.classList.add('hide')

      if (!flatList) {
        backBtn.classList.add('hide')
        controlMobileBtn.classList.add('hide');
      }
      getPassInfo();
      const widthDesc = window.innerWidth;
      if(widthDesc > 1023 ) {
        btnOfferLate.classList.add('active')
      }

    } else if(fullMoney > 0 && groupName == 'credit2') {
      flatWrapper.classList.remove('active')
      flatsPass.classList.add('active')
      controlMobile.classList.remove('flat');
      controlMobile.classList.add('pass')
      controlMobileBtn.innerHTML = 'Вернуться к объектам'
      paramsColumn.classList.add('hide')
      flatColumn.classList.add('flats-active')
      flatTip.classList.remove('active');
      chosenFlat.classList.add('hide');
      pageHero.classList.add('hide')
      getPassInfo();
      if (!flatList) {
        backBtn.classList.add('hide')
        controlMobileBtn.classList.add('hide');
      }
    }
    else {
      getActiveWarn();
      getFlatLate();
    }


  }


//* pass info *//
  let infoData;

  function getPassInfo() {
    getACFFields();
  }


  /*send send send*/
  passFormWrap.addEventListener('submit', (e) => {
    e.preventDefault(); // Предотвращаем обычное поведение формы
    let finishWrap = document.querySelector('.finish__wrapper')
    flatsPass.classList.remove('active')
    finishWrap.classList.add('active');
    controlMobile.classList.add('hide');
    controlMobileBtn.classList.add('hide')
    btnOfferLate.classList.add('hide')
    let messSteps = document.querySelectorAll('.steps__item')
    let mess = document.querySelector('.info__deal').innerHTML
    let messStep = messSteps[0].querySelector('.item__text').innerHTML
    let messStep2 = messSteps[1].querySelector('.item__text').innerHTML
    let messStep3 = messSteps[2].querySelector('.item__text').innerHTML
    let email = passFormInput.value;
    let typeMess = formSendType
    let passStash = document.querySelector('.pass__my .row--stash .money__value').innerHTML
    let passOwnFlat = document.querySelector('.pass__my .row--flat .money__value').innerHTML

    // Создаем объект XMLHttpRequest
    let xhr = new XMLHttpRequest();
    xhr.open('POST', '/wp-content/themes/vsedoma/process.php', true); // Замените 'process.php' на путь к вашему PHP-скрипту

    // Устанавливаем заголовок для отправки данных формы
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

    // Отправляем данные на сервер
    xhr.send('email=' + encodeURIComponent(email) + '&mess=' + encodeURIComponent(mess) + '&messStep=' + encodeURIComponent(messStep)+ '&messStep2=' + encodeURIComponent(messStep2) + '&messStep3=' + encodeURIComponent(messStep3) + '&messType=' + encodeURIComponent(typeMess)+ '&passStash=' + encodeURIComponent(passStash)+ '&passOwnFlat=' + encodeURIComponent(passOwnFlat)

    )

    // Обработка ответа от сервера
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        console.log(xhr.responseText); // Выводим ответ сервера в консоль
      }
    };
  });





// Функция для выполнения AJAX-запроса
  let pageId = 27;


  function makeAjaxRequest(method, url, data, priceNewFlat, callback) {
    let xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          callback(JSON.parse(xhr.responseText));
        } else {
          console.error('Error:', xhr.statusText);
        }
      }
    };
    xhr.send(data);
  }

// Функция для получения и вывода значений полей
  function getACFFields(priceNewFlat) {
    let data = 'action=get_acf_fields&page_id=' + pageId + '&group_name=' + groupName;

    makeAjaxRequest('POST', '/wp-admin/admin-ajax.php', data, priceNewFlat, function (response) {
      console.log(data)


      // infoPass.querySelector('.info__deal').innerHTML = titleFlat ? response['title'] + ' ' + titleFlat : response['title']

      infoPass.querySelectorAll('.steps__item').forEach((item, index) => {
        item.querySelector('.item__text').innerHTML = response[Number(index) + 1 + 'step']
      })
      let infoPassPrice = infoPass.querySelector('.info__price');
      console.log(response)
      let infoItogo = document.querySelector('.info__text--final')
      infoItogo.innerHTML = response['itogo']
      if (groupName == 'credit1') {
        infoPass.querySelector('.info__deal').innerHTML = response['title'] + ' ' + Math.round(cost*0.65).toLocaleString('ru') + '₽' + ', ' + response['main'] + ' ' + '~' + Math.round(fullMoney / 180).toLocaleString('ru') + '₽ / месяц';
        // infoPass.querySelector('.info__addr').innerHTML = response['main']
        // infoPassPrice.innerHTML = '~' + Math.floor((Number(response['proczent_naczenki']) / 100 + 1) * smallestPrice + (stash.value ? Number(stash.value.replace(/ /g,'')) : 0) + mc).toLocaleString('ru') + '₽';
      } else if (groupName == 'credit2') {
        infoPass.querySelector('.info__deal').innerHTML = response['title'] + ' ' + fullMoney.toLocaleString('ru') + '₽' + ', ' + response['main'] + ' ' + '~' + Math.round(fullMoney / 180).toLocaleString('ru') + '₽ / месяц';
        // infoPass.querySelector('.info__addr').innerHTML = response['main']
        // infoPassPrice.innerHTML = '~' + Math.floor(smallestPrice + (Number(monthly.value.replace(/ /g,'')) / (Number(response['proczent_naczenki'])/100)) * 0.8 + (stash.value ? Number(stash.value.replace(/ /g,'')) : 0) + mc).toLocaleString('ru') + '₽';
      } else if (groupName == 'ipoteka1') {
        // titleFlat = titleFlat.match(/\d+/)[0] + 'х комнатной квартиры. <br>'
        let stFull = Number(stash.value.replace(/ /g, '')) + cost
        infoPass.querySelector('.info__deal').innerHTML = response['title'] + ' ' + indexFlat + ' ' + response['main'] + ' ' + '~' + Math.round((Number(priceFlat.replace(/&nbsp;/g, '')) - stFull) / 180).toLocaleString('ru') + '₽ / месяц'
        // infoPass.querySelector('.info__addr').innerHTML =
        // infoPassPrice.innerHTML = '~' + smallestPrice.toLocaleString('ru') + '₽';
      } else if (groupName == 'ipoteka2') {
        infoPass.querySelector('.info__deal').innerHTML = response['title'] + ' ' + indexFlat + ' ' + response['main'] + ' ~' + Math.round(Number(priceFlat.replace(/&nbsp;/g, '')) / 180).toLocaleString('ru') + '₽ / месяц'
      } else {
        console.log('warn in group name')
      }

    });
  }

  const animationOwn = lottie.loadAnimation({
    container: constForm, // контейнер для анимации
    renderer: 'svg', // тип рендерера (может быть 'svg', 'canvas' или 'html')
    loop: true, // зацикливание анимации
    autoplay: false, // автоматический запуск анимации
    path: '/wp-content/themes/vsedoma/front/app/js/loading.json' // путь к вашему JSON-файлу с анимацией
  });
  const animationFindColumn = lottie.loadAnimation({
    container: columnFloatLoading, // контейнер для анимации
    renderer: 'svg', // тип рендерера (может быть 'svg', 'canvas' или 'html')
    loop: true, // зацикливание анимации
    autoplay: false, // автоматический запуск анимации
    path: '/wp-content/themes/vsedoma/front/app/js/loading.json' // путь к вашему JSON-файлу с анимацией
  });
  const animationFind = lottie.loadAnimation({
    container: findPlate, // контейнер для анимации
    renderer: 'svg', // тип рендерера (может быть 'svg', 'canvas' или 'html')
    loop: true, // зацикливание анимации
    autoplay: false, // автоматический запуск анимации
    path: '/wp-content/themes/vsedoma/front/app/js/loadingwhite.json' // путь к вашему JSON-файлу с анимацией
  });


} else if(window.location.pathname == '/karta/') {

  //toggle controls
  let mapHadBtn = document.querySelector('.panel__had')
  let mapWantBtn = document.querySelector('.panel__want')
  let mapHadWrap = document.querySelector('.form__had')
  let mapWantWrap = document.querySelector('.form__want')
  let mapHadArea = document.querySelector('.had__area .input--area')
  let mapHadAddress = document.querySelector('.had__input.input--address')

  //tips
  let firtstTip = document.querySelector('.first')
  let firtstTipCheck = 0
  let warningTip = document.querySelector('.warnings')
  let flatTip = document.querySelector('.flats-info')
  let staticTip = document.querySelector('.static')
  let panelHadTip = document.querySelector('.tip--want')
  let panelWantTip = document.querySelector('.tip--had')
  let panelHadTipCheck = 0
  let panelWantTipCheck = 0

  document.querySelector('.toggleWant').addEventListener('click', () => {
    firtstTip.classList.add('hide')
    toggleWrap(mapHadBtn, mapHadWrap)
    firtstTip.classList.add('hide')
    warningTip.classList.remove('hide')
    firtstTip.classList.remove('active')
    if(panelWantTipCheck !== 1) {
      panelHadTip.classList.remove('active')
      panelWantTip.classList.add('active')
    }
  })


  mapHadBtn.addEventListener('click', () => {
    toggleWrap(mapHadBtn, mapHadWrap)
    firtstTip.classList.add('hide')
    warningTip.classList.remove('hide')
    if(panelWantTipCheck !== 1) {
      panelHadTip.classList.remove('active')
      panelWantTip.classList.add('active')
    }
  })
  mapWantBtn.addEventListener('click', () => {
    toggleWrap(mapWantBtn, mapWantWrap)
    warningTip.classList.add('hide')
    if(panelHadTipCheck !== 1) {
      panelHadTip.classList.add('active')
      panelWantTip.classList.remove('active')
    }

  })

  firtstTip.querySelector('.tip__close').addEventListener('click', () => {
    firtstTip.classList.remove('active')
    firtstTip.querySelector('.tip').classList.remove('active')
  })
  staticTip.querySelector('.tip__close').addEventListener('click', () => {
    staticTip.classList.remove('active')
    staticTip.querySelector('.tip').classList.remove('active')
  })
  document.querySelector('.flats-info .tip__close').addEventListener('click', () => {
    document.querySelector('.flats-info .tip').classList.remove('active')
  })


  closeTip(panelHadTip, panelHadTipCheck)
  closeTip(panelWantTip, panelWantTipCheck)
function closeTip(item, check) {
  item.querySelector('.tip__close').addEventListener('click', () => {
    item.classList.remove('active')
    item.querySelector('.tip').classList.remove('active')
    check = 1;
  })
}




  //change inputs
  let mapHadStash = document.querySelector('.input--stash')
  let mapHadMonthly = document.querySelector('.input--monthly')
  let mapOwnFlat = document.querySelector('.had__input.input--address')
  formatNumber(mapHadStash)
  formatNumber(mapHadMonthly)


function toggleWrap(btn, wrap) {
  [mapHadBtn, mapWantWrap,mapHadWrap,mapWantBtn].forEach( item => {
    item.classList.remove('active');
  })
  btn.classList.add('active');
  wrap.classList.add('active');
}





  ymaps.modules.define('ext.paintOnMap', ['meta', 'util.extend', 'pane.EventsPane', 'Event'], function (provide, meta, extend, EventsPane, Event) {
    'use strict';

    let EVENTS_PANE_ZINDEX = 500;
    let DEFAULT_UNWANTED_BEHAVIORS = ['drag', 'scrollZoom'];
    let DEFAULT_STYLE = {strokeColor: '#581DFF', strokeWidth: 1, strokeOpacity: 1}; // Change the stroke color here
    let DEFAULT_TOLERANCE = 16;

    let badFinishPaintingCall = function () {
      throw new Error('(ymaps.ext.paintOnMap) некорректный вызов PaintingProcess#finishPaintingAt. Рисование уже завершено.');
    };

    function paintOnMap(map, positionOrEvent, config) {
      config = config || {};
      let style = extend(DEFAULT_STYLE, config.style || {});

      let unwantedBehaviors = config.unwantedBehaviors === undefined ?
          DEFAULT_UNWANTED_BEHAVIORS : config.unwantedBehaviors;

      let pane = new EventsPane(map, {
        css: {position: 'absolute', width: '100%', height: '100%'},
        zIndex: EVENTS_PANE_ZINDEX + 50,
        transparent: true
      });

      map.panes.append('ext-paint-on-map', pane);

      if (unwantedBehaviors) {
        map.behaviors.disable(unwantedBehaviors);
      }

      let canvas = document.createElement('canvas');
      let ctx2d = canvas.getContext('2d');
      let rect = map.container.getParentElement().getBoundingClientRect();
      canvas.width = rect.width;
      canvas.height = rect.height;

      ctx2d.globalAlpha = style.strokeOpacity;
      ctx2d.strokeStyle = style.strokeColor;
      ctx2d.lineWidth = style.strokeWidth;

      canvas.style.width = '100%';
      canvas.style.height = '100%';

      pane.getElement().appendChild(canvas);

      let firstPosition = positionOrEvent ? toPosition(positionOrEvent) : null;
      let coordinates = firstPosition ? [firstPosition] : [];

      let bounds = map.getBounds();
      let latDiff = bounds[1][0] - bounds[0][0];
      let lonDiff = bounds[1][1] - bounds[0][1];

      canvas.onmousemove = function (e) {
        coordinates.push([e.offsetX, e.offsetY]);

        ctx2d.clearRect(0, 0, canvas.width, canvas.height);
        ctx2d.beginPath();

        ctx2d.moveTo(coordinates[0][0], coordinates[0][1]);
        for (let i = 1; i < coordinates.length; i++) {
          ctx2d.lineTo(coordinates[i][0], coordinates[i][1]);
        }

        ctx2d.stroke();
      }.bind(this);

      let paintingProcess = {
        finishPaintingAt: function () {
          paintingProcess.finishPaintingAt = badFinishPaintingCall;

          map.panes.remove(pane);
          if (unwantedBehaviors) {
            map.behaviors.enable(unwantedBehaviors);
          }

          let tolerance = config.tolerance === undefined ? DEFAULT_TOLERANCE : Number(config.tolerance);
          if (tolerance) {
            coordinates = simplify(coordinates, tolerance);
          }

          return coordinates.map(function (x) {
            let lon = bounds[0][1] + (x[0] / canvas.width) * lonDiff;
            let lat = bounds[0][0] + (1 - x[1] / canvas.height) * latDiff;
            return meta.coordinatesOrder === 'latlong' ? [lat, lon] : [lon, lat];
          });
        }
      };

      return paintingProcess;
    }

    function toPosition(positionOrEvent) {
      return positionOrEvent instanceof Event ?
          [positionOrEvent.get('offsetX'), positionOrEvent.get('offsetY')] :
          positionOrEvent;
    }

    function simplify(coordinates, tolerance) {
      let toleranceSquared = tolerance * tolerance;
      let simplified = [coordinates[0]];

      let prev = coordinates[0];
      for (let i = 1; i < coordinates.length; i++) {
        let curr = coordinates[i];
        if (Math.pow(prev[0] - curr[0], 2) + Math.pow(prev[1] - curr[1], 2) > toleranceSquared) {
          simplified.push(curr);
          prev = curr;
        }
      }

      return simplified;
    }

    provide(paintOnMap);
  });

//want controls
let mapRoomWants = document.querySelectorAll('.want__toggle .list__item')
let mapRoomsWantsCount = 1

//had controls
  let mapRoomHad = document.querySelectorAll('.had__toggle .list__item')
  let mapRoomsHadCount = 1
//want area
let mapAreaWants = document.querySelector('.form__want .area__value')
let mapAreaWantsCount = 20

let wrapper = document.querySelector('.wrapper__info')
let mapOfferWrapper = document.querySelector('.offer__wrapper')
let openWrapper =  wrapper.querySelector('.offer__open')
let flatWrapperList = wrapper.querySelector('.offer__list')
let offerflatСount = wrapper.querySelector('.count__value')
let mapPassWrapper = wrapper.querySelector('.pass__wrapper')
let mapFinishWrapper = wrapper.querySelector('.finish__wrapper')
let mapControlOffer = wrapper.querySelector('.control__back')


mapAreaWants.oninput = () => {
  mapAreaWantsCount = mapAreaWants.value
}
mapAreaWants.onblur = () => {
  mapAreaWantsCount = mapAreaWants.value
  mapAreaWants.parentNode.classList.remove('active')
}

  mapAreaWants.addEventListener('focus', () => {
    mapAreaWants.parentNode.classList.add('active')
  })


openWrapper.addEventListener('click', () => {
  wrapper.classList.remove('active--full')
  openWrapper.classList.remove('active')
  document.querySelector('.offer__tap').classList.remove('hide');
})

  document.querySelector('.offer__tap').addEventListener('click', () => {
    wrapper.classList.add('active--full')
    openWrapper.classList.add('active')
    document.querySelector('.offer__tap').classList.add('hide');
  })

mapControlOffer.addEventListener('click', () => {
  mapOfferWrapper.classList.add('active')
  mapPassWrapper.classList.remove('active')
  document.querySelector('.panel').classList.remove('hide')
})


//want toggle rooms
mapRoomWants.forEach(item => {
  item.addEventListener('click', () => {
    mapRoomWants.forEach( el => {
      el.classList.remove('active')
    })
    item.classList.add('active');
    mapRoomsWantsCount = item.dataset.room
  })
})
  mapRoomHad.forEach(item => {
    item.addEventListener('click', () => {
      mapRoomWants.forEach( el => {
        el.classList.remove('active')
      })
      item.classList.add('active');
      mapRoomsHadCount = item.dataset.room
    })
  })



    let mapContainer = document.querySelector('#map'); // Получите элемент блока #map

  ymaps.ready(['ext.paintOnMap']).then(function () {
    let map = new ymaps.Map('map', {
      center: [55.755811, 37.617617],
      zoom: 12,
      controls: []
    });

    let paintProcess = null;
    let collectedCoordinates = [];
    let myPolygon;
    let alredyPaint = 0

    let testButton = document.querySelector('.want__region');
    let isDrawingEnabled = false; // Изначально режим рисования выключен

    let previousGeoObject = null; // Переменная для хранения предыдущего геообъекта

    let mousedownHandler = function (e) {
      paintProcess = ymaps.ext.paintOnMap(map, e, { style: {} });
    };

    testButton.addEventListener('click', function (e) {
      testButton.classList.add('active')
      document.querySelector('.ymaps-2-1-79-map ymaps').classList.add('active')
      if (isDrawingEnabled) {
        // Отключаем режим рисования
        map.events.remove('mousedown'); // Удалите обработчик события mousedown
        map.events.remove('mouseup'); // Удалите обработчик события mouseup
        paintProcess = null;
        isDrawingEnabled = false;

      } else {
        // Включаем режим рисования
        // map.events.add('mousedown', function (e) {
        //   paintProcess = ymaps.ext.paintOnMap(map, e, { style: {} });
        // });
        map.events.add('mousedown', mousedownHandler);
        map.events.add('mouseup', function (e) {
          if (paintProcess) {
            collectedCoordinates = [];
            let coordinates = paintProcess.finishPaintingAt();
            paintProcess = null;
            let newGeoObject = new ymaps.Polygon([coordinates], {}, {
              fillColor: 'rgba(88,29,255,0.11)',
              strokeColor: 'rgba(88,29,255,0.25)'
            });

            if (previousGeoObject) {
              map.geoObjects.remove(previousGeoObject);
            }

            collectedCoordinates = collectedCoordinates.concat(coordinates);
            map.geoObjects.add(newGeoObject);
            previousGeoObject = newGeoObject;
          }

          let coordTest = convertToRectangle(collectedCoordinates);
          getFlatOnMap(coordTest, mapRoomsWantsCount, mapAreaWantsCount);

          // Отключаем режим рисования после завершения рисования
          isDrawingEnabled = false;
          map.events.remove('mousedown', mousedownHandler);
          testButton.classList.remove('active')
          document.querySelector('.ymaps-2-1-79-map ymaps').classList.remove('active')
        });

        isDrawingEnabled = true;
      }
    });



    let dataArray

    //Поиск квартир
    function getFlatOnMap(coordinates,roomCount,areaCount) {
      let formattedString = formatCoordinates(coordinates);
      let reqStr = '&region_code=77';
      reqStr += '&room_numbers=' + roomCount
      reqStr += '&min_area=' + areaCount
      reqStr += formattedString ? '&poly_vertex=' + formattedString : null
      fetch('https://d5duiqnab9gjniqmttue.apigw.yandexcloud.net/property_ads_catalog?api_key=BKNTgMETEfGZjRKprWCau3W4bX2Nn3QHj5ExQE3k' + reqStr)
        .then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Error: ' + response.status);
          }
        })
        .then(function (data) {
          document.querySelector('.flats-info').classList.remove('hide')
          // Преобразование объекта данных в массив объектов
          dataArray = Object.values(data);
          map.geoObjects.removeAll();
          getFlats(dataArray);
        })
        .catch(function (error) {
          console.log(error);
        });

    }
function getFlats(data) {
  data.forEach(function (item) {
    let priceItem = item.currency == '$' ? item.price * 100 : item.price
    if(allMoney >= Number(priceItem) || allMoney === undefined ) {
      let coordinates = [item.lat, item.lon];

      let placemark = new ymaps.Placemark(coordinates, {
          // Дополнительная информация о точке
          hintContent: item.address,
          balloonContent:
              "Адрес: " + item.address + "<br>" +
              "Цена: " + item.price.toLocaleString('ru') + " " + item.currency,

        }, {
          // preset: 'islands#blueCircleIcon',
          iconLayout: 'default#image',
          iconImageSize: [14, 14],
          iconImageHref: '/wp-content/themes/vsedoma/front/app/img/iconflatinmap.svg'
        }
      );

      map.geoObjects.add(placemark);
    } else {
    }
  });
  wrapper.classList.add('active')
  mapOfferWrapper.classList.add('active')
  flatWrapperList.replaceChildren();
  Object.values(data).forEach((item) => {
    let priceItem = item.currency == '$' ? item.price * 100 : item.price
    if(allMoney >= Number(priceItem) || allMoney === undefined) {
      let generatedHTML = generateHTML(item);
      flatWrapperList.insertAdjacentHTML('afterbegin', generatedHTML);
    } else {
    }
  })
  if(flatWrapperList.querySelectorAll('.list__item').length  == 0) {
    document.querySelector('.flats-info .text__value').innerHTML = 'Попробуйте поменять настройки фильтра или ввести все данные.'
  } else {
    document.querySelector('.flats-info .text__value').innerHTML = 'Система подобрала объекты в рамках “ваших возможностей” и пожеланий.'
  }
  offerflatСount.innerHTML = flatWrapperList.querySelectorAll('.list__item').length
  getPass(mapOfferWrapper, mapPassWrapper)
}
    /*geo подстановка адреса в форму продажи своей квартиры*/
    ymaps.ready(init);

    function init(input) {
      let suggestView = new ymaps.SuggestView(document.querySelector('.had__input.input--address'), {
        provider: {
          suggest: (function (request, options) {
            return ymaps.suggest("Россия" + ", " + request);
          })
        }
        // boundedBy: [[41.1859504, 19.6389224], [82.169819, 179.793251]], // Границы России
        // strictBounds: true // Строгие границы, только в пределах boundedBy
      });
      suggestView.events.add("select", function (e) {
        mapAddress = e.get("item").value;

        // Геокодируем выбранный адрес
        ymaps.geocode(mapAddress).then(function (res) {
          mapFirstGeoObject = res.geoObjects.get(0);
          let region = mapAddress
          mapRegion = region.split(',')[0];

          checkMapAddress = 1
          checkOwmFlat()

        });
      });
    }

    let infoWarnMoney = document.querySelector('.info__warn')
    let infoOp = document.querySelector('.info__op')

    function checkOwmFlat() {
      if(mapAddress  && checkMapAddress && mapHadArea.value != 0 && mapArea != mapHadArea.value.trim() ) {
        mapSaveOwnFlat.classList.add('active')
        warningTip.classList.add('hide')
        warningTip.querySelector('.tip').classList.remove('active')
      } else {
        mapSaveOwnFlat.classList.remove('active')
        warningTip.classList.remove('hide')
        warningTip.querySelector('.tip').classList.add('active')
      }
    }
    let allMoney
    let passStash = document.querySelector('.row--stash .money__value')
    let passOwnFlat = document.querySelector('.row--flat .money__value')
    function getOwnMoney() {
      let stash = document.querySelector('.flat__item.stash')
      let monthly = document.querySelector('.flat__item.month')
      let price = document.querySelector('.flat__item.price')
      if(Number(mapHadStash.value.replace(/[^\d.]/g, '')) != 0 || Number(mapHadMonthly.value.replace(/[^\d.]/g, '')) != 0 || Number(mapPriceFlat) != 0) {
        infoWarnMoney.classList.add('hide')
        infoOp.classList.remove('hide')
        stash.innerHTML = mapHadStash.value.toLocaleString('ru')
        monthly.innerHTML = mapHadMonthly.value.toLocaleString('ru')
        price.innerHTML = Number(mapPriceFlat).toLocaleString('ru')
        if (Number(mapPriceFlat) && !!stash.innerHTML.replace(/ /g, '') && !!monthly.innerHTML.replace(/ /g, '')) {
          allMoney = Math.round((Number(monthly.innerHTML.replace(/ /g, '')) / 0.012) * 0.8 + Number(stash.innerHTML.replace(/ /g, ''))  + Number(mapPriceFlat))

          document.querySelector('.info').classList.add('active')
        } else if (Number(mapPriceFlat) && !!stash.innerHTML.replace(/ /g, '')) {
          allMoney = Math.round((Number(stash.innerHTML.replace(/ /g, '')) / 0.1)  + Number(mapPriceFlat) * 1.3)
          document.querySelector('.info').classList.add('active')
        } else if (Number(mapPriceFlat) && !!monthly.innerHTML.replace(/ /g, '')) {
          allMoney = Math.round((Number(monthly.innerHTML.replace(/ /g, '')) / 0.012) * 0.8  + Number(mapPriceFlat))

          document.querySelector('.info').classList.add('active')
        } else if (!!monthly.innerHTML.replace(/ /g, '') && !!stash.innerHTML.replace(/ /g, '')) {
          allMoney = Math.round(Number(monthly.innerHTML.replace(/ /g, '')) / 0.012 + Number(stash.innerHTML.replace(/ /g, '')))

          document.querySelector('.info').classList.add('active')
        } else if (Number(mapPriceFlat)) {
          allMoney = Math.round(Number(mapPriceFlat) * 1.3)

          document.querySelector('.info').classList.add('active')
        } else if (!!stash.innerHTML.replace(/ /g, '')) {
          allMoney = Math.round(Number(stash.innerHTML.replace(/ /g, '')) * 10)

          document.querySelector('.info').classList.add('active')
        } else if (!!monthly.innerHTML.replace(/ /g, '')) {
          allMoney = Math.round(Number(monthly.innerHTML.replace(/ /g, '')) / 0.012)

          document.querySelector('.info').classList.add('active')
        } else {

        }
        passStash.innerHTML = mapHadStash.value.toLocaleString('ru')
        passOwnFlat.innerHTML = mapPriceFlat.toLocaleString('ru')
        infoOp.innerHTML = `Ваши возможности  <span>~${Math.round(allMoney).toLocaleString('ru')}</span> `

        if(dataArray) {
          getFlats(dataArray)
        }


      } else {
        infoWarnMoney.classList.remove('hide')
        infoOp.classList.add('hide')
        document.querySelector('.info').classList.remove('active')
      }
    }
    mapHadStash.oninput = function () {
      getOwnMoney()
    }
    mapHadStash.onblur = function () {
      getOwnMoney()
    }
    mapHadMonthly.oninput = function () {
      getOwnMoney()
    }
    mapHadMonthly.onblur = function () {
      getOwnMoney()
    }

    mapHadArea.oninput = function () {
      checkOwmFlat()

    }

    mapHadArea.onblur = function () {
      checkOwmFlat()
      mapHadArea.parentNode.classList.remove('active')
    }

    mapHadArea.addEventListener('focus', () => {
      mapHadArea.parentNode.classList.add('active')
    })

    //had controls
    let mapRoomHad = document.querySelectorAll('.had__toggle .list__item')
    let mapRoomsHadCount = 1
    mapRoomHad.forEach(item => {
      item.addEventListener('click', () => {
        mapRoomHad.forEach( el => {
          el.classList.remove('active')
        })
        item.classList.add('active');
        mapRoomsHadCount = item.dataset.room

        checkOwmFlat()
      })
    })
    //
    // document.querySelector('.had__room').addEventListener('change', function (e) {
    //   checkOwmFlat()
    // })

    function  mapGetPriceFlat(area,address,reqNumRegion) {
      area = area.value
      reqNumRegion = 77

      // if (reqNumRegion) {
      fetch('https://d5duiqnab9gjniqmttue.apigw.yandexcloud.net/price_prediction?token=BKNTgMETEfGZjRKprWCau3W4bX2Nn3QHj5ExQE3k&region_code=' + reqNumRegion + '&property_type=flat&address=' + address + '&living_area=' + area)
        .then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Error: ' + response.status);
          }
        })
        .then(function (data) {

          mapPriceFlat = data.body.cost
          mapFlatCoord = [data.body.lat,data.body.lon]
          console.log(data.cost)
          let placemark = new ymaps.Placemark(mapFlatCoord, {
                // Дополнительная информация о точке

              }, {
                // preset: 'islands#blueCircleIcon',
                iconLayout: 'default#image',
                iconImageSize: [30, 30],
                iconImageHref: '/wp-content/themes/vsedoma/front/app/img/myflat.svg'
              }
          );

          map.geoObjects.add(placemark);
          getOwnMoney();

        })
        .catch(function (error) {
          console.log(error);
          // checkWarn()
        });

    }

  let indexFlat,titleFlat, priceFlat
    function getPass(of, pas) {
      let flatsBtn = document.querySelectorAll('.offer__list .item__btn')
      flatsBtn.forEach((el, index) => {
        el.addEventListener('click', () => {
          of.classList.remove('active')
          pas.classList.add('active')
          document.querySelector('.panel').classList.add('hide')
          indexFlat = el.parentNode.childNodes[1].innerHTML.toString()
          titleFlat = el.parentNode.childNodes[0].innerHTML
          priceFlat = el.previousElementSibling.innerHTML
          flatCheck(titleFlat, priceFlat)
          function test() {
            let stash = document.querySelector('.flat__item.stash')
            let monthly = document.querySelector('.flat__item.month')
            let price = document.querySelector('.flat__item.price')
            let kv = Number(mapPriceFlat) != 0 ? Number(mapPriceFlat) : 0
            let st = stash.innerHTML.replace(/ /g, '') == undefined ? stash.innerHTML.replace(/ /g, '') : 0
            let monthCredit = Math.round((Number(priceFlat.replace(/&nbsp;/g, '')) - st - Number(kv))
                / 180).toLocaleString('ru');
            document.querySelector('.pass__info .info__deal').innerHTML = 'Ваша цель - купить квартиру стоимостью ' + priceFlat + ' ваш ежемесячный платеж составит ' + monthCredit + 'руб. в мес.'
          }
          if(priceFlat) {
            test()
          }
        })
      })
    }
    let mapSaveOwnFlat = document.querySelector('.panel__save')
    let mapAddress, mapRegion, mapFirstGeoObject, mapArea = 0, mapRoom = 1, checkMapAddress = 0,mapPriceFlat = 0, mapFlatCoord
    mapSaveOwnFlat.addEventListener('click', () => {
      mapGetPriceFlat(mapHadArea, mapAddress, mapAddress)
      mapSaveOwnFlat.classList.remove('active')
      mapSaveOwnFlat.innerHTML = 'Изменить'
    })

  }).catch(console.error);

  function flatCheck(title, price) {
    document.querySelector('.pass__flat .item__title').innerHTML = title
    document.querySelector('.pass__flat .item__price').innerHTML = price
  }

  const passFormSend = document.querySelector('.form__wrapper');
  const passFormButtons = passFormSend.querySelectorAll('.form__tab');
  const passFormInput = passFormSend.querySelector('.form__input');

  passFormButtons.forEach((button) => {
    button.addEventListener('click', (e) => {
      e.preventDefault();
      passFormButtons.forEach((el) => {
        el.classList.remove('active');
      })
      e.target.classList.add('active');
      checkSend(e.target);
    })
  });
  let formSendType = 'email';
  function checkSend(btn) {
    formSendType = btn.dataset.send;
    if (formSendType != 'email') {
      passFormInput.setAttribute("type", "text")
      passFormInput.placeholder = '+7 (999) 999-9999';
      Inputmask({
        mask: '+7 (999) 999-9999',
      }).mask(passFormInput);
      passFormInput.value = ''
    } else {
      passFormInput.setAttribute("type", "email")
      Inputmask.remove(passFormInput);
      passFormInput.placeholder = 'Mail@mail.ru'
      passFormInput.value = ''
    }
  }
  const passFormWrap = document.querySelector('.pass__form');
  passFormWrap.addEventListener('submit', (e) => {
    e.preventDefault(); // Предотвращаем обычное поведение формы
    let finishWrap = document.querySelector('.finish__wrapper')
    mapPassWrapper.classList.remove('active')
    finishWrap.classList.add('active');
    let messSteps = document.querySelectorAll('.steps__item')
    let mess = document.querySelector('.info__deal').innerHTML
    let messStep = messSteps[0].querySelector('.item__text').innerHTML
    let messStep2 = messSteps[1].querySelector('.item__text').innerHTML
    let messStep3 = messSteps[2].querySelector('.item__text').innerHTML
    let email = passFormInput.value;
    let typeMess = formSendType
    let passStash = document.querySelector('.pass__my .row--stash .money__value').innerHTML
    let passOwnFlat = document.querySelector('.pass__my .row--flat .money__value').innerHTML

    // Создаем объект XMLHttpRequest
    let xhr = new XMLHttpRequest();
    xhr.open('POST', '/wp-content/themes/vsedoma/process.php', true); // Замените 'process.php' на путь к вашему PHP-скрипту

    // Устанавливаем заголовок для отправки данных формы
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

    // Отправляем данные на сервер
    xhr.send('email=' + encodeURIComponent(email) + '&mess=' + encodeURIComponent(mess) + '&messStep=' + encodeURIComponent(messStep)+ '&messStep2=' + encodeURIComponent(messStep2) + '&messStep3=' + encodeURIComponent(messStep3) + '&messType=' + encodeURIComponent(typeMess)+ '&passStash=' + encodeURIComponent(passStash)+ '&passOwnFlat=' + encodeURIComponent(passOwnFlat)
  );
    // Обработка ответа от сервера
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        console.log(xhr.responseText); // Выводим ответ сервера в консоль
      }
    };
  });

  let infoPassWrapperMap = document.querySelector('.pass__wrapper .info__wrapper')
  let infoPassBtnMap = document.querySelector('.pass__wrapper .info__btn')
  let infoPassMap = document.querySelector('.pass__wrapper .pass__info')
  infoPassBtnMap.addEventListener('click', () => {
    if (!infoPassWrapperMap.classList.contains('active')) {
      infoPassBtnMap.querySelector('.btn__text').innerHTML = 'Скрыть';
    } else {
      infoPassBtnMap.querySelector('.btn__text').innerHTML = 'Раскрыть';
    }
    infoPassWrapperMap.classList.toggle('active');
    infoPassBtnMap.classList.toggle('active');
    infoPassMap.classList.toggle('active');
  })



//Фигура в прямоугольник
function convertToRectangle(coordinates) {
    if (coordinates.length < 2) {
      // Не хватает точек для создания прямоугольника
      return null;
    }

    let minLat = coordinates[0][0];
    let maxLat = coordinates[0][0];
    let minLon = coordinates[0][1];
    let maxLon = coordinates[0][1];

    // Находим минимальные и максимальные значения широты и долготы
    for (let i = 1; i < coordinates.length; i++) {
      let lat = coordinates[i][0];
      let lon = coordinates[i][1];

      if (lat < minLat) {
        minLat = lat;
      } else if (lat > maxLat) {
        maxLat = lat;
      }

      if (lon < minLon) {
        minLon = lon;
      } else if (lon > maxLon) {
        maxLon = lon;
      }
    }

    // Создаем координаты для вершин прямоугольника
    let rectangleCoordinates = [
      [minLat, minLon], // Левая нижняя вершина
      [minLat, maxLon], // Правая нижняя вершина
      [maxLat, maxLon], // Правая верхняя вершина
      [maxLat, minLon], // Левая верхняя вершина
    ];

    return rectangleCoordinates;
  }










function formatCoordinates(coordinates) {
  if (!Array.isArray(coordinates)) {
    return '';
  }

  // Пройдемся по массиву координат и создадим строку в нужном формате
  let formattedString = coordinates.map(function (coord) {
    return '(' + coord[0].toFixed(2) + ',' + coord[1].toFixed(2) + ')';
  }).join(',');

  return '[' + formattedString + ']';
}
}


